body{
  font-family: 'Roboto Mono', monospace !important;
}
/* ---nav bar css--- */
.pointing{
  cursor: pointer;
}

.nav-link{
  font-weight: 200;
  letter-spacing: 0.7px;
  cursor: pointer;

text-transform: uppercase;
color: beige !important;
padding: 20px !important;
border-bottom: 4px solid  #464a47 ;


}
.navbar{
  background-color: #464a47 !important;
  position: fixed !important; 
  z-index: 99999999;
}
.nav-link:focus{
  border-bottom: 4px solid #dc3545;
}
.nav-link:focus{
  color: rgb(66, 170, 195) !important;
  font-weight: bolder;
}
.nav-link:hover{
  border-bottom: 4px solid rgb(66, 170, 195);
  
}
.navbar-brand{
  color: antiquewhite;
  font-size: 30px !important;
  font-weight: 500;
  
}
.khanal{
  color: #dc3545 !important ;
  font-weight: 1000 !important;
}

/* Header css */

.auto-typing{
  font-size: 30px;
  font-weight: 500;
  color: white ;
 text-transform: capitalize;

}
.main-header-wrapper{
  position: relative;
  background:   linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.8)),url(first.jpg) no-repeat ;
  height: 100vh;
  background-size: cover;
  background-position: center; 

}
.main-info{
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 3;
}
.main-info h2{
  color: #dc3545;
  font-weight: 800;
  font-weight: bolder;
  text-transform: capitalize;
}
.main-info h3{
  color: white;
  font-size: 30px;
}
.btn{
color: white !important;
text-transform: uppercase !important;
}

.btn:hover{
  transition: .2s ease-in-out !important;
}

/* particles css */
.tsparticles-canvas-el{
  position: absolute;
  height: 90vh;
  z-index: 1;
}
@media (max-width:768px){
  .particles{
    display: none;
  }
  .auto-typing,.main-info h3,.main-info h2{
    font-size: 20px !important;
  }

}

/* education css */
.experience{
  background-color: #dde9e0;
  

}
.line{
  height:100px;
  border-left: 3px solid blueviolet;
}
.schooling{
  color: #dc3545;
  font-weight: bolder;
  font-size: larger;
}
.plus2{
  color: #dc3545;
  font-weight: bolder;
  font-size: larger;
}
.p2{
  color: #94484f;
  font-weight: bolder;
  font-size: smaller !important;
}
.book{
  background-color: rgb(187, 148, 64);
padding: 10px;
height: 50px;
width: 50px;
border-radius: 100%;
margin-top: -45px;
margin-left: -30px;
}
.book:hover{
background-color: rgb(182, 66, 45) !important;

}
.higherstudy{
  margin-left: 62px;
}
@media (max-width:1100px){
  

  .higherstudy{
    margin-left: 46px;
  }
}
@media (max-width:991px){
  .book{
  margin-top: -60px;
  }
  .line{
    height:120px;
  }
  .line2{
    margin-top: -12px;
  }

  .higherstudy{
    margin-left: 40px;
  }
 

}
@media (max-width:767px){
.line{
  display: none;
}
.book{
  display: none;
}
.higherstudy{
  margin-left: 0px;
}

}
.clogname{
  color: #dc3545;
  font-weight: bolder;
  font-size: larger;
}
/* @media (max-width:465px){
  .experience{
    height: 100vh;
  } */


/* skills */
.fas {
  padding: 0px;
  /* font-size: 70px; */
  /* width: 50px; */
  text-align: center;
  text-decoration: none;
  margin: 5px 2px;

  color:#dc3545;
}
.fas:hover {
  opacity: 1;
  color:#35bbdc;
}


/* contact */
.white{
  margin-top: 0px !important;
  z-index: 9999999 !important;
  position: absolute;
  top: 3200px;
  left: 340px;

}
.white-paper{
  width: 240px !important;
  height: 50px !important;
}


h5:hover{
  opacity: 0.7;
}

@media (max-width:1085px){
  .white{
    
    top: 2950px;
    left: 180px;
  
  }
}




@media (max-width:768px){
  .white{
    
   display: none;
  
  }
}






/* icons css */
.fab {
  padding: 20px;
     font-size: 30px;
     width: 30px;
     height:30px;
     text-align:center;
     border-radius: 10%;
     text-decoration: none;
     margin: 5px 2px;
 }
 
 .fab:hover {
     opacity: 0.7;
 }


.fa-google {
  /* background: #dd4b39; */
  color: rgb(224, 72, 72);
}



.fa-instagram {

  color: rgb(221, 34, 34);
}